'use client';
import { ThemeVariant } from '@/constants';
import {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';

interface ThemeContextProps {
  theme: ThemeVariant;
  toggleTheme: () => void;
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const savedTheme =
    typeof window !== 'undefined'
      ? (localStorage.getItem('theme') as ThemeVariant)
      : ThemeVariant.Light;
  const [theme, setTheme] = useState<ThemeVariant>(savedTheme);

  const toggleTheme = () => {
    const newTheme =
      theme === ThemeVariant.Light ? ThemeVariant.Dark : ThemeVariant.Light;
    setTheme(newTheme);
  };

  useEffect(() => {
    document.body.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};
